import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { URLS } from "src/_config";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface ForgotPasswordFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

export default function ForgotPasswordComponent() {
  const { control, errors, handleSubmit, reset } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  //Call API Hook
  const userApi = useUserApi();
  const history = useHistory();
  const toast = useToaster();

  const onSubmit = (val: any) => {
    console.log({ val });
    const params = {
      email: val.email,
      user_type: 2,
    };
    userApi.callForgotPassword(
      params,
      (message: any, resp: any) => {
        console.log(resp);
        // history.push(URLS.LANDING);
        reset();
        toast.success(message);
      },
      (message: any) => {
        toast.error(message);
      }
    );
  };

  return (
    <div className="login-box">
      <div className="container">
        <div className="login-body">
          <div className="login-logo-part">
            <img src="/images/log.png" alt="#" />
            <h1>Forgot Password</h1>
          </div>
          <hr className="login-hr" />
          <form className="login-form">
            <div className="mb-3">
              {/* <input type="text" className="form-control" placeholder="Email address" /> */}
              <Controller
                control={control}
                name="email"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormTextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    type="text"
                    error={errors.email}
                    placeholder="Email"
                  />
                )}
              />
            </div>
            <div className="mb-5">
              <button type="submit" className="btn" onClick={handleSubmit(onSubmit)}>
                {/* <button type="button" className="btn"> */}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
