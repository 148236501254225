import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { URLS } from "src/_config";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      passwordRegExp,
      "Password must contain at least 1 capital letter, 1 small letter, 1 digit, and 1 special character, minimum 8 characters"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

export default function ResetPasswordComponent() {
  const { control, errors, handleSubmit } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { token } = useParams<any>();
  const query = new URLSearchParams(window.location.search);
  const userType = query.get("user_type");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  // Call API Hook
  const userApi = useUserApi();
  const history = useHistory();
  const toast = useToaster();

  const onSubmit = (val: any) => {
    const params = {
      password: val.password,
      confirm_password: val.password,
      token,
      user_type: userType,
    };
    console.log({ params });
    userApi.callResetPassword(
      params,
      (message: any, resp: any) => {
        console.log(resp);
        toast.success(message);
        history.push(URLS.LOGIN);
      },
      (message: any) => {
        toast.error(message);
      }
    );
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <div className="login-box">
      <div className="container">
        <div className="login-body">
          <div className="login-logo-part">
            <img src="/images/log.png" alt="#" />
            <h1>Reset Password</h1>
          </div>
          <hr className="login-hr" />
          <form className="login-form">
            <div className="mb-3 password-field-show">
              <Controller
                control={control}
                name="password"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormTextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    type={showPassword ? "text" : "password"}
                    error={errors.password}
                    placeholder="Password"
                  />
                )}
              />
              <div onMouseDown={() => toggleShowPassword()} onMouseUp={() => toggleShowPassword()}>
                <i className={"fa fa-eye" + (!showPassword ? "" : "-slash")} />
              </div>
            </div>
            <div className="mb-3 password-field-show">
              <Controller
                control={control}
                name="confirmPassword"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormTextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    type={showConfirmPassword ? "text" : "password"}
                    error={errors.confirmPassword}
                    placeholder="Confirm Password"
                  />
                )}
              />
              <div onMouseDown={() => toggleShowConfirmPassword()} onMouseUp={() => toggleShowConfirmPassword()}>
                <i className={"fa fa-eye" + (!showConfirmPassword ? "" : "-slash")} />
              </div>
            </div>
            <div className="mb-5">
              <button type="submit" className="btn" onClick={handleSubmit(onSubmit)}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
