import React, { useEffect } from "react";
import ResetPasswordComponent from "src/components/auth/resetPassword";

export default function ResetPasswordPage() {
  return (
    <React.Fragment>
      <ResetPasswordComponent />
    </React.Fragment>
  );
}
