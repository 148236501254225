import React, { useEffect } from "react";
import ForgotPasswordComponent from "src/components/auth/forgotPassword";

export default function ForgotPasswordPage() {
  return (
    <React.Fragment>
      <ForgotPasswordComponent />
    </React.Fragment>
  );
}
